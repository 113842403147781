import React from "react";
import Typography from "@mui/material/Typography";

function ResultsFooter() {
  return (
    <>

      <Typography paragraph={true}>You should now contact the supplier of your choice to discuss your requirement in more detail. Before appointing a supplier you should ensure that they have satisfactory qualifications, accreditation, experience and insurance appropriate for the work.</Typography>
      <Typography paragraph={true}>An email with the above prices and contact details has been sent to you, so you can refer to it later at your convenience.</Typography>
      <Typography paragraph={true}>If you need more information or help regarding your home, please call us on 0330 088 2050</Typography>

    </>
  );
}

export default ResultsFooter;
